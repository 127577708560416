<template>
  <div class="col-lg-6 col-md-12 col-xl-4">
    <div class="card tItem overflow-hidden">
      <div class="item-card9-img">
        <div class="item-card9-imgs">
          <router-link
            v-if="item.education_type != 3"
            :to="'/education/' + item.shortlink + '-EDU' + item.id"
          ></router-link>
          <a
            v-if="item.education_type == 3"
            data-bs-toggle="modal"
            :data-bs-target="'#' + index"
            ><i
              class="mdi mdi-arrow-right-drop-circle-outline"
              aria-hidden="true"
            ></i
          ></a>
          <img
            :src="'https://storage.terapivitrini.com/' + item.thumbnail"
            alt="img"
            class="cover-image"
          />
        </div>
      </div>
      <div class="card-body">
        <div class="item-card9">
          <router-link :class="'badge badge-primary'" :to="'/education/' + item.shortlink + '-EDU' + item.id">{{
            item.education_type == 1
              ? "Yüz Yüze"
              : item.education_type == 2
              ? "Online"
              : "Video"
          }}</router-link>
          <router-link
            :to="'/education/' + item.shortlink + '-EDU' + item.id"
            class="text-dark mt-2"
            ><h4 class="font-weight-semibold mt-1">
              {{ item.title }}
            </h4></router-link
          >
          <p class="mb-0 leading-tight">
            {{ item.short_description }}
          </p>
          <div class="item-card9-desc mt-2">
            <router-link
              :to="'/education/' + item.shortlink + '-EDU' + item.id"
              class=""
              ><span v-if="item.education_type != 3" class=""
                ><i class="fa fa-calendar-o text-muted me-1"></i>
                {{ item.begin_date }}</span
              >
              <span v-if="item.education_type == 3" class=""
                ><i class="fa fa-film text-muted me-1"></i>
                {{ item.module_size }} modül</span
              >
            </router-link>
            <span style="float: right"
              ><i class="fa fa-clock-o text-muted me-1"></i>
              {{ item.duration }} saat</span
            >
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="item-card9-footer d-flex">
          <div class="item-card9-cost w-100">
            <h4 class="text-dark font-weight-semibold mb-0 mt-0">
              <template v-if="item.dumping_price > 0">
                <span class="float-left">{{ item.dumping_price }}TL</span
                ><span class="float-right"
                  ><del>{{ item.price }}TL</del></span
                >
              </template>
              <template v-else> {{ item.price }}TL </template>
            </h4>
          </div>
        </div>
      </div>
      <div
        v-if="item.education_type == 3"
        class="modal fade"
        :id="index"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal-dialog" id="homeVideo" role="document">
          <div class="modal-content" style="border: none; background: #121212">
            <button
              type="button"
              class="btn btn-default"
              data-bs-dismiss="modal"
              @click="pauseVid()"
            >
              x
            </button>
            <div class="embed-responsive embed-responsive-16by9">
              <video
                v-if="selected != null"
                :id="'hls-video' + index"
                class="embed-responsive-item"
                controls="controls"
                style="width: auto; height: auto"
              >
                <source
                  :src="'https://storage.terapivitrini.com/' + selected.path"
                  type="video/mp4"
                />
              </video>
            </div>
            <div v-for="(a, b) in item.path" :key="b">
              <div class="row p-2 black" @click="selec(a)">
                <div class="col-1 text-center">
                  <i class="fa fa-play"></i>
                </div>
                <div class="col-8">
                  {{ a.title }}
                </div>

                <div class="col-3 text-center" v-if="a.duration != null">
                  {{ convertTime(a.duration) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.badge:hover{
  color:#ccc  !important
}
.black {
  background: #121212;
  margin: 0;
  color: white;
}
.black:hover {
  background: #2b2b2b;
  cursor: pointer;
}
.float-right {
    float: right;
}
.float-left {
    float: left;
}
</style>
<script>
import VideoPlayerGeneral from './VideoPlayerGeneral.vue';

export default {
    props: ["item", "index"],
    mounted() {
        $("#" + this.index).on("show.bs.modal", function (e) {
            window.location.hash = "video-series";
        });
        let _this = this;
        $(window).on("hashchange", function (event) {
            if (window.location.hash != "#video-series") {
                $("#" + _this.index).modal("hide");
            }
        });
        $("#" + this.index).modal({
            backdrop: "static",
            keyboard: false,
        });
        if (this.item != "" && this.item.education_type == 3) {
            this.selected = this.item.path[0];
        }
    },
    data() {
        return {
            selected: [],
        };
    },
    methods: {
        convertTime(time) {
            var sec_num = parseInt(time, 10); // don't forget the second param
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - hours * 3600) / 60);
            var seconds = sec_num - hours * 3600 - minutes * 60;
            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            return hours + ":" + minutes + ":" + seconds;
        },
        selec(a) {
            this.selected = a;
            var video = document.getElementById("hls-video" + this.index);
            video.load();
            video.play();
        },
        pauseVid() {
            var video = document.getElementById("hls-video" + this.index);
            video.pause();
        },
    },
    components: { VideoPlayerGeneral }
};
</script>