<template>
  <div>
    <section>

      <Breadcrumb
        title="Eğitim"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: false, url: '', text: 'Eğitim' },
        ]"
      />
    </section>

    <!--Contact-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-8 col-md-12">
            <!--Add lists-->
            <div class="mb-lg-0">
              <div >
                <div class="item">

                  <div class="tab-content">
                    <div class="row">
                      <template v-for="(item, b) in educationList">
                        <Item2 id="mobileEx"  :item="item" :index="'list2' + b" />
                      </template>
                    </div>
                  </div>
                </div>
                <div class="center-block text-center">
                  <Pagination
                    :currentpage="currentpage"
                    :pagesize="pagesize"
                    :preload="'/education/page/'"
                  />
                </div>
              </div>
            </div>
            <!--Add lists-->
          </div>
          <!--Right Side Content-->
          
          <Sidebar :category="categoryList" @response="filterProcess($event)" :ads="ads" />
          <!--Right Side Content-->
        </div>
      </div>
    </section>
    <!--Contact-->
  </div>
</template>
  <style scoped>
  @media screen and (max-width: 990px) {
  #mobileEx {
    padding-left:35px;
    padding-right:35px;
  }
}
.contactItem {
  line-height: 45px;
}
.social-item {
  float: left;
  width: 40px;
  background: #f2f2f2;

  margin: 5px;
  padding: 5px;
  text-align: center;
  font-size: 20px;
  border-radius: 50px;
}
.social-item > a {
  color: #bb7494 !important;
}
@media (max-width: 990px) {
  .contactItem {
    line-height: 0px;
    padding-bottom: 15px;
  }
}
</style>
  <script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import Sidebar from "./Components/Sidebar.vue";
import Item1 from "./Components/Item1.vue";
import Item2 from "./Components/Item2.vue";
import Pagination from "../../components/Pagination.vue";
export default {
  metaInfo() {
    return {
      title: "Eğitim Listesi - Terapi Vitrini",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Eğitim Listesi - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    let _this = this;
    $(document).ready(function () {
      let content = document.getElementById("appcontent").innerHTML;
      _this.$store.dispatch("setMetaContent", [
        "Eğitim Listesi - Terapi Vitrini",
        "Eğitim Listesi - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        "",
        "",
        "",
        content,
      ]);
    });
    let educationInfo = {
      begin: (this.currentpage - 1) * this.perpage,
      perpage: this.perpage,
    };
    this.$store.dispatch("getEducationList", educationInfo).then((value) => {
      this.ads=value.ads;
      this.educationList = value.list;
      this.categoryList = value.categorys;
      this.totalsize = value.size;
      this.pagesize = value.pages;
      this.educationLoad = false;
    });
  },
  data() {
    return {
      ads:[],
      educationList: [],
      categoryList: [],
      currentpage: 1,
      perpage: 12,
      totalsize: 0,
      pagesize: 0,
      educationLoad: true,
      search: "",
      category: "",
      maxPrice: "",
      minPrice: "",
      type: "",
      orderPrice: "",
    };
  },
  components: { Breadcrumb, Sidebar, Item1, Item2, Pagination },
  methods: {
    filterProcess(e) {
      this.currentpage = 1;

      this.search = e.search;
      this.category = e.category;
      this.maxPrice = e.maxPrice;
      this.minPrice = e.minPrice;
      this.type = e.type;

      let educationInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        search: this.search,
        category: this.category,
        maxPrice: this.maxPrice,
        minPrice: this.minPrice,
        type: this.type,
        orderPrice: this.orderPrice,
      };
      this.$store.dispatch("getEducationList", educationInfo).then((value) => {
        this.educationList = value.list;
        this.categoryList = value.categorys;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.educationLoad = false;
      });
    },

    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
  watch: {
    "$route.params.page": function () {
      this.blogLoad = true;
      this.currentpage = this.$route.params.page;
      let educationInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
      };
      this.$store.dispatch("getEducationList", educationInfo).then((value) => {
      this.ads=value.ads;
      this.educationList = value.list;
      this.categoryList = value.categorys;
      this.totalsize = value.size;
      this.pagesize = value.pages;
      this.educationLoad = false;
    });
    },
  },
  mounted() {
    this.backToTop();
  },
};
</script>